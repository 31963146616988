@import '../../../node_modules/foundation-sites/scss/util/unit';
@import '../../../node_modules/foundation-sites/scss/util/breakpoint';
@import '../../../node_modules/foundation-sites/scss/util/mixins';
@import "../setup/colors";
$global-flexbox: true;

.recommendations {
  margin-top: 85px;

  @include breakpoint(large) {
    margin-top: 65px;
  }

  .grid-container {
    padding-left: 20px;
    padding-right: 20px;
  }


  @include breakpoint(small) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @include breakpoint(medium) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.complete-your-look-wrapper{
    &.hide{
      display: none;
    }

    margin-top: 70px;
    .grid-container{
      margin-top: 40px;

      .separator{
        background-color: black;
      }
    }

    /*@include breakpoint(small only) {
      .owl-stage {
        padding-right: 40px !important;
        padding-left: 0px !important;
      }

      .owl-stage.no-right-padding {
        padding-right: 0px !important;
        padding-left: 40px !important;
      }
    }*/

  }

}

.slot-carousel-wrapper {
  &.full-width{
    padding: 0 10px;
    .products-carousel{
      padding: 0;
    }
  }
  .slots-carousel{
    padding: 0;

    .slides{
      position: relative;
      padding: 0 5px;

      .owl-stage-outer{
        overflow: hidden;

        .owl-stage {
          @include clearfix;

          .owl-item {
            float: left;
          }
        }
      }

      .owl-nav{
        .owl-prev,
        .owl-next {
          position: absolute;
          width: 15px;
          height: 15px;
          top: 40%;
          font-size: rem-calc(28);
          color: $black;
          margin-top: -19px;
          transition: opacity 0.3s ease;

          &::after{
            content: '';
            border-style: solid;
            display: block;
            height: 14px;
            width: 14px;
            transform: rotate(45deg);

            @include breakpoint (small only){
              height: 12px;
              width: 12px;
            }
          }
        }
        .owl-prev {
          left: -12px;
          @include breakpoint (large){
            left: -20px;
          }
          &::after{
            border-width: 0 0 2px 2px;
          }
        }
        .owl-next {
          right: -15px;
          @include breakpoint (large ){
            right: -20px;
          }
          &::after{
            border-width: 2px 2px 0 0;
          }
        }
        .owl-prev.disabled,
        .owl-next.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }
      }

      .owl-dots {
        position: relative;
        display: none;
        margin-top: 10px;
        
        &:not(.disabled){
          @include breakpoint (small only){
            display: flex;
          }
        }
      }

      .owl-dots, .owl-nav{
        &.disabled{
          .owl-prev, .owl-next{
            display: none;
          }
        }
      }
    }
  }

  @include breakpoint (medium) {
    .margin-top-mobile, .margin-bottom-mobile{
      display: none !important;
    }
  }
  @include breakpoint (small only){
    .margin-top-desktop, .margin-bottom-desktop{
      display: none !important;
    }
  }
}
.results-wrapper{
  .slot-carousel-wrapper{
    padding: 0px;
  }
}

.pdp-carousels{
  margin-top: 40px;
  margin-bottom: 30px;

  .recommendations{
    margin-top: 0;
    &.pdp-recently-viewed{
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .pdp-carousels__callOuts{
    display: flex;
    justify-content: center;
    padding: 0 32px;

    @include breakpoint(small only) {
      border-bottom: 1px solid #000;
      padding: 0;
      margin-bottom: 20px;
    }

    .callout {
      color: #000;
      border: 1px solid #000;
      border-bottom: none;
      cursor: pointer;
      margin: 0;
      transition: all .35s ease-in-out;
      width: 400px;
      text-align: center;
      font-size: 1rem;
      padding: 14px;

      @include breakpoint(small only) {
        width: 50%;
        padding: 10px;
      }


      &.active {
        color: white;
        background-color: black;
        font-weight: bolder;
        transition: all .35s ease-in-out
      }

    }
  }

  .pdp-recently-viewed.active,.pdp-product-recommendations.active {
    display: block
  }

  .pdp-recently-viewed:not(.active),.pdp-product-recommendations:not(.active) {
    display: none
  }

  .grid-container {
    .separator {
      background-color: black;
    }

    &.tab-structure{
      h5 {
        display: none;
      }

      .separator{
        margin-top: 0;

        @include breakpoint(small only) {
          display: none;
        }
      }
    }

  }
}